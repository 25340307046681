<template>
  <v-container
    class="pa-0"
    fluid
  >
    <div
      class="admin-content-wrapper mx-2 mt-2"
      :class="{ 'column': $vuetify.breakpoint.smAndDown }"
    >
      <div>
        <AdminNavDrawer />
      </div>
      <div>
        <router-view />
      </div>
    </div>
  </v-container>
</template>

<script>
import AdminNavDrawer from './AdminNavDrawer'

export default {
  name: 'Admin',
  components: {
    AdminNavDrawer
  }
}
</script>

<style lang="scss" scoped>
.admin-content-wrapper {
  display: grid;
  grid-template-columns: 250px 1fr;
  column-gap: 2%;
  row-gap: 20px;
  overflow-x: scroll;
  &.column {
    grid-template-columns: 1fr;
  }
}
</style>
