<template>
  <v-navigation-drawer
    permanent
    style="border-radius: 5px;"
    width="100%"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          Admin zone
        </v-list-item-title>
        <!-- <v-list-item-subtitle>
          Explore and configure
        </v-list-item-subtitle> -->
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list
      dense
      nav
    >
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="item.link"
        exact
        :class="item.inset && ['ml-12', 'v-list-item--active']"
      >
        <v-list-item-icon class="mr-4">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('elements')

export default {
  name: 'AdminNavDrawer',
  computed: {
    ...mapState(['element']),
    items () {
      let items = [
        { title: 'Dashboard', icon: 'mdi-monitor-dashboard', link: '/admin' },
        { title: 'Templates', icon: 'mdi-view-dashboard', link: '/admin/templates' },
        { title: 'Categories', icon: 'mdi-filter-variant', link: '/admin/categories' },
        { title: 'Variables', icon: 'mdi-variable', link: '/admin/variables' },
        {},
        { title: 'Structural Diversity', icon: 'mdi-window-open-variant', link: '/admin/structuralDiversities' },
        {},
        { title: 'Ecosystem Data', icon: 'mdi-window-open-variant', link: '/admin/ecosystems' },
        { title: 'Ecosystem Data Categories', icon: 'mdi-shape-plus-outline', link: '/admin/ecosystemCategories' },
        {},
        { title: 'Irrigation Type Options', icon: 'mdi-window-open-variant', link: '/admin/irrigationTypes' },
        {},
        { title: 'Material Data', icon: 'mdi-window-open-variant', link: '/admin/materials' },
        { title: 'Material Data Categories', icon: 'mdi-shape-plus-outline', link: '/admin/materialCategories' },
        {},
        { title: 'Nursery Data', icon: 'mdi-window-open-variant', link: '/admin/nurseries' },
        { title: 'Nursery Data Categories', icon: 'mdi-shape-plus-outline', link: '/admin/nurseryCategories' },
        {},
        { title: 'Planting Type Options', icon: 'mdi-window-open-variant', link: '/admin/plantingTypes' },
        {},
        { title: 'Transportation Options', icon: 'mdi-window-open-variant', link: '/admin/transportationOptions' },
        { title: 'Transportation Distance Options', icon: 'mdi-shape-plus-outline', link: '/admin/transportationDistanceOptions' }

        // { title: 'Projects', icon: 'mdi-map-marker', link: '/admin/projects' }
      ]
      const showNested = ['editTemplate', 'newTemplate'].includes(this.$route.name)
      const showNestedProduct = ['editProduct', 'newProduct'].includes(this.$route.name)
      if (showNested || showNestedProduct) {
        const icon = this.$route.params.id ? 'mdi-pencil' : 'mdi-plus'
        const title = this.$route.params.id ? this.element.name : (showNested ? 'New template' : 'New product')
        items.splice(2, 0, { title, icon, inset: true })
      }
      return items
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
